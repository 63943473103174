import React from 'react';
import Layout from '../../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

class CaseDetails extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">Work Details</h2>
                  <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Work Details
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115">
          <Container>
            <Row>
              <Col lg="12">
                <div className="case-details-thumb">
                  <img src="assets/images/case-details-thumb.jpg" alt="" />
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="case-details-content mt-50 pb-20">
                  <p>
                    As a result, most of us need to know how to use computers. Our
                    knowledge of computers will help us to tap into challenging career
                    opportunities and enhance the quality of our lives. It is imperative
                    that quality students be encouraged and motivated to study computers
                    and become capable and responsible IT professionals. The education
                    model needs to align itself with dynamically changing technology to
                    meet the growing need for skilled IT manpower and deliver
                    state-of-the-art, industry relevant and technology ready programs.​
                    Today, the term Information Technology (IT) has ballooned to
                    encompass many aspects of computing and technology and the term is
                    more recognizable than ever before. The Information Technology
                    umbrella can be quite large, covering many fields. IT professionals
                    perform a variety of duties that range from installing applications,
                    managing information, to designing complex applications, managing
                    computer networks and designing and managing databases.
                  </p>
                  <div className="case-details-case mt-25">
                    <img src="assets/images/case-content-thumb.jpg" alt="" />
                    <p>
                      Over the years, a wide range of developments and innovations in
                      the global IT arena have led to many new IT-enabled devices and
                      services being produced. Moreover, there is need for IT today, not
                      just in urban areas but rural regions as well. Countries and
                      populations that are led by the growing class of high-tech workers
                      and entrepreneurs will be the ones at the forefront of IT
                      development. As a result, most of us need to know how to use
                      computers. Our knowledge of computers will help us to tap into
                      challenging career opportunities and enhance the quality of our
                      lives. It is imperative that quality students be encouraged and
                      motivated to study computers and become capable and responsible IT
                      professionals.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-1.jpg" alt="" />
                </div>
              </Col>
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-2.jpg" alt="" />
                </div>
              </Col>
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-3.jpg" alt="" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25">
                  <h4 className="title">How it Works?</h4>
                  <p>
                    As a result, most of us need to know how to use computers. Our
                    knowledge of computers will help us to tap into challenging career
                    opportunities and enhance the quality of our lives. It is imperative
                    that quality students be encouraged and motivated to study computers
                    and become capable and responsible IT professionals. The education
                    model needs to align itself with dynamically changing technology to
                    meet the growing need for skilled IT manpower and deliver
                    state-of-the-art, industry relevant and technology ready programs.​
                    Today, the term Information Technology (IT) has ballooned to
                    encompass many aspects of computing and technology and the term is
                    more recognizable than ever before. The Information Technology
                    umbrella can be quite large, covering many fields. IT professionals
                    perform a variety of duties that range from installing applications,
                    managing information, to designing complex applications, managing
                    computer networks and designing and managing databases.
                  </p>
                </div>
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"http://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div>
                <div className="case-next-prev d-flex justify-content-between pt-50">
                  <div className="case-prev">
                    <Link  to={"/"}>
                      <span>Prev Post</span>
                      <h4 className="title">Tips On Minimalist</h4>
                    </Link>
                  </div>
                  <div className="case-next text-right">
                    <Link  to={"/"}>
                      <span>next Post</span>
                      <h4 className="title">Less Is More</h4>
                    </Link>
                  </div>
                  <Link  to={"/"}>
                    <img src="assets/images/dot-box.png" alt="" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default CaseDetails;