import {
  faLaptopCode
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const dataWorkingProcess = [
  {
    title: 'Definition',
    desc: 'Gather project requirements. Determine project scope and propose proposal. Baseline the project plan.',
    image: '',
  },
  {
    title: 'Execution',
    desc: 'Project implementation Ensure the product meet the requirements and quality.',
    image: '',
  },
  {
    title: 'Q&A Testing',
    desc: 'Perform quality and assurance (Q&A) testing accordingly to the planned process.',
    image: '',
  },
  {
    title: 'Delivery',
    desc: 'Source code and all deliverables. We also support the deployment of the system.',
    image: '',
  },
  {
    title: 'Improvement & Maintenance',
    desc: 'Report the result to the customer and continue the maintenance and improvement process.',
    image: '',
  },
];
class WhyChoosePart extends React.Component {
  render() {
    return (
      <div className='why-choose-area'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg='8'>
              <div className='section-title text-center'>
                <h2 className='title'>Our Working Process</h2>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            {dataWorkingProcess.map((item, indx) => (
              <div
                key={indx}
                className='col-lg-4 col-md-6 col-sm-9 wow slideInUp'
                data-wow-duration='.5s'
                data-wow-delay='0s'
              >
                <div className='single-choose text-center mt-30'>
                  <div className='icon-box'>
                    <span />
                    <FontAwesomeIcon
                      icon={faLaptopCode}
                      size={`4x`}
                      style={{ color: 'white' }}
                    />
                  </div>
                  <h4 className='title'>{item.title}</h4>
                  <p>{item.desc}</p>
                </div>
              </div>
            ))}
          </Row>
        </Container>
        <div className='choose-dot'>
          <img src='assets/images/choose-dot.png' alt='' />
        </div>
        <div className='choose-shape'>
          <img src='assets/images/choose-shape.png' alt='' />
        </div>
      </div>
    );
  }
}

export default WhyChoosePart;
