import React from 'react';
//import { toast } from 'react-toastify';

import Banner from '../../componets/banner/Index';
import Brand2Part from '../../componets/brand2Part/Index';
import DevelopmentMethodologies from '../../componets/DevelopmentMethodologies/Index';
import LatestServicesPart from '../../componets/LatestServicesPart/Index';
import TeamMemberPart from '../../componets/teamMemberPart/Index';
import { default as WhoWeAreArea } from '../../componets/whoWeAreArea/Index';
import WhyChoosePart from '../../componets/whyChoosePart/Index';
import Layout from '../../layouts';

class HomePage extends React.Component {
  render() {
    return (
      <Layout>
        <Banner />
        <WhoWeAreArea />
        <LatestServicesPart />
        <WhyChoosePart />
        <DevelopmentMethodologies />
        <TeamMemberPart />
        <Brand2Part />
      </Layout>
    );
  }
}

export default HomePage;
