import {
  faChalkboard,
  faLaptopCode,
  faLongArrowAltRight,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class WhoWeAreArea extends React.Component {
  render() {
    return (
      <div className='who-we-are-area pt-110 pb-120'>
        <Container>
          <Row>
            <div
              className='col-lg-5 wow fadeInLeft'
              data-wow-duration='1s'
              data-wow-delay='0s'
            >
              <div className='section-title'>
                <span> WHAT WE DO </span>
                <h2 className='title'>Work smart play smart</h2>
              </div>
              {/* section title */}
            </div>
            <div
              className='col-lg-6 offset-lg-1 wow fadeInRight'
              data-wow-duration='1.5s'
              data-wow-delay='0s'
            >
              <div className='section-title'>
                <p>
                  Perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium dolorem-quelaudantium, totam rem aperiam eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quiavoluptas sit aspernatur aut odit aut fugit, sed quia
                  quuntur magni dolores eos ratione voluptatem sequi nesciunt
                  eque porroe.
                </p>
              </div>
              {/* section title */}
            </div>
          </Row>
          <Row>
            <div
              className='col-lg-4 col-md-6 col-sm-6 wow slideInUp h-100'
              data-wow-duration='1.5s'
              data-wow-delay='0s'
            >
              <div className='what-we-do-item text-center mt-30'>
                <FontAwesomeIcon icon={faLaptopCode} />
                <h5 className='title'>Web Development</h5>
                <p>
                  We provide a morden and secure web application development
                  solutions with cutting-edge technologies and methodologies
                  matching with customer's business flow and requirements.
                  {/* Understanding user's behaviours and experiences is our goal to
                  reach in designing and developing web application. */}
                </p>
                <Link to={'/'}>
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </Link>
              </div>
              {/* what we do item */}
            </div>
            <div
              className='col-lg-4 col-md-6 col-sm-6 wow slideInUp h-100'
              data-wow-duration='.5s'
              data-wow-delay='0s'
            >
              <div className='what-we-do-item text-center mt-30 h-100'>
                <FontAwesomeIcon icon={faMobileAlt} />
                <h5 className='title'>Mobile Development</h5>
                <p>
                  We have expertise in both native and cross-platform
                  technologies. We empower your business and experiences with
                  expressive and feature-rich native iOS / Android,
                  cross-platform or Progressive Web Apps.
                </p>
                <Link to={'/'}>
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </Link>
              </div>
              {/* what we do item */}
            </div>
            <div
              className='col-lg-4 col-md-6 col-sm-6 wow slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0s'
            >
              <div className='what-we-do-item text-center mt-30'>
                <FontAwesomeIcon icon={faChalkboard} />
                <h5 className='title'>Consultation</h5>
                <p>
                  We provide consulting and development solutions in information
                  technology and digital transformation using advanced
                  technologies to meet the business needs and user experiences
                  of our clients.
                </p>
                <Link to={'case-details'}>
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </Link>
              </div>
              {/* what we do item */}
            </div>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
        <div className='what-we-are-shape-1'>
          <img src='/assets/images/what-we-are-shape-1.png' alt='shape' />
        </div>
        <div className='what-we-are-shape-2'>
          <img src='/assets/images/what-we-are-shape-2.png' alt='shape' />
        </div>
      </div>
    );
  }
}

export default WhoWeAreArea;
