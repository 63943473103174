import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './styles.scss';
class DevelopmentMethodologies extends React.Component {
  render() {
    return (
      <div className='team-member-area mt-40'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg='6'>
              <div className='section-title text-center'>
                <h2 className='title'>
                  DEVELOPMENT <br /> METHODOLOGIES
                </h2>
              </div>
            </Col>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
        <div className='container'>
          <Row className='justify-content-center'>
            <div className='col-lg-4 col-md-4 col-sm-6 gap-20'>
              <div className={'wrapper'}>
                <p className='stt'>1</p>
                <h4>
                  Agile <br /> Development
                </h4>
                <img
                  src='/assets/images/agile_development.png'
                  alt='team-member'
                />
              </div>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6'>
              <div className={'wrapper'}>
                <p className='stt'>2</p>
                <h4>
                  DevOps <br /> Development
                </h4>
                <img src='/assets/images/dev_op.png' alt='team-member' />
              </div>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6'>
              <div className={'wrapper'}>
                <p className='stt'>3</p>
                <h4>
                  Waterfall <br /> Development
                </h4>
                <img
                  style={{ width: '80%' }}
                  src='/assets/images/waterfall_development.png'
                  alt='team-member'
                />
              </div>
            </div>
          </Row>
          {/* row */}
        </div>
        {/* container fluid */}
      </div>
    );
  }
}

export default DevelopmentMethodologies;
