import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaChevronDown } from "react-icons/fa";
import styles from './remoteScannerDownload.module.scss';

const DownloadLinks = () => {
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://api.megiservices.com/msa-scanner/api/Utilities/Download'
        );
        if (response.data.StatusCode === 200) {
          setLinks(response.data.Payload);
        } else {
          setError('Failed to fetch data');
        }
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Dropdown className={styles.dropdownCustom}>
      <Dropdown.Toggle className={styles.dropdownToggle}>
        Download Remote Scanner <FaChevronDown className={styles.chevronIcon}/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {links.map((link, index) => (
          <Dropdown.Item
            key={index}
            href={link.Url}
            target='_blank'
            rel='noopener noreferrer'
          >
            Get Remote Scanner for {link.Platform}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownloadLinks;
