import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PartialNav extends React.Component {
  render() {
    return (
      <>
        <Nav className='mr-auto'>
          <li className='nav-item'>
            <Link className='nav-link ' to={'/'}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link' to={'/services'}>
              Our Services
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link' to={'#'}>
              Out Products
            </Link>
            <ul className='sub-menu'>
              {/* <li>
                <Link to={'/services'}>Smart Crawler</Link>
                <ul className='sub-menu'>
                  <li>
                    <Link to={'/services'}>Download</Link>
                  </li>
                  <li>
                    <Link to={'/services-details'}>Installation Guide</Link>
                  </li>
                  <li>
                    <Link to={'/services-details'}>User guide</Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to={'#'}>Remote Scanner</Link>
                <ul className='sub-menu' style={{
                  top:0
                }}>
                  <li>
                    <Link to={'/remote-scanner-download'}>Download</Link>
                  </li>
                  {/* <li>
                    <Link to={'/services-details'}>Installation Guide</Link>
                  </li> */}
                  <li>
                    <Link to={'/remote-scanner-user-guide'}>User guide</Link>
                  </li>
                </ul>
              </li>
            </ul>
            {/* sub menu */}
          </li>
          <li className='nav-item'>
            <Link className='nav-link' to={'/contact'}>
              Contact us
            </Link>

            {/* sub menu */}
          </li>
        </Nav>
      </>
    );
  }
}

export default PartialNav;
