import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AboutPage from './pages/about';
import CaseStudy from './pages/case-study';
import CaseDetails from './pages/case-study/details';
import ComingSoon from './pages/coming-soon';
import ContactPage from './pages/contact';
import FaqPage from './pages/faq';
import Index2 from './pages/home/index-2';
import Index3 from './pages/home/index-3';
import Index4 from './pages/home/index-4';
import PricingPage from './pages/pricing';
import ProductPage from './pages/product';
import ProductDetailPage from "./pages/product-details";
import ServicesPage from './pages/service';
import ServicesDetails from './pages/service/details';
import ShopPage from './pages/shop';
import ShopDetailsPage from './pages/shop/details';

import BlogDetails from './pages/blog/blog-details';
import BlogDetails2 from './pages/blog/blog-details2';
import BlogGrid from './pages/blog/blog-grid';
import BlogStandard from './pages/blog/blog-standard';
import TeamPage from './pages/team';
import TeamDetailsPage from './pages/team/details';

import Preloader from './componets/preloader/index';
import { RemoteScannerDownload } from './componets/remoteScannerDownload';
import { RemoteScannerUserGuide } from './componets/remoteScannerUserGuide';
import NoMatch from './pages/errors/no-match';
import HomePage from './pages/home/index';
import { PrivacyPolicies } from './pages/privacy-policies';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={'/'}>
        <Preloader />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/index" component={HomePage} />
          <Route path="/index2" component={Index2} />
          <Route path="/index3" component={Index3} />
          <Route path="/index4" component={Index4} />

          <Route path="/about" component={AboutPage} />
          <Route path="/services" component={ServicesPage} />
          <Route path="/services-details" component={ServicesDetails} />

          <Route path="/case-study" component={CaseStudy} />
          <Route path="/case-details" component={CaseDetails} />
          <Route path="/coming-soon" component={ComingSoon} />
          <Route path="/error" component={NoMatch} />
          <Route path="/faq" component={FaqPage} />
          <Route path="/pricing" component={PricingPage} />

          <Route path="/products" component={ProductPage} />
          <Route path="/products-details" component={ProductDetailPage} />
          <Route path="/shop" component={ShopPage} />
          <Route path="/shop-details" component={ShopDetailsPage} />

          <Route path="/team" component={TeamPage} />
          <Route path="/team-details" component={TeamDetailsPage} />
          <Route path="/blog-standard" component={BlogStandard} />
          <Route path="/blog-grid" component={BlogGrid} />
          <Route path="/blog-details" component={BlogDetails} />
          <Route path="/blog-details2" component={BlogDetails2} />

          <Route path="/contact" component={ContactPage} />
          <Route path="/remote-scanner-download" component={RemoteScannerDownload} />
          <Route path="/remote-scanner-user-guide" component={RemoteScannerUserGuide} />
          <Route path="/privacy-policies" component={PrivacyPolicies} />

          <Route component={NoMatch} />
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

export default App;

