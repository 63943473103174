import React, { useState } from 'react';
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap';
import styles from '../remoteScannerUserGuide.module.scss';
const dataUserGuide = [
  {
    step: 0,
    title: 'Download the Application',
    details: [
      'Download the Remote Scanner Client application on Windows or Mac.',
      '<a href="https://megiservices.com/remote-scanner-download">https://megiservices.com/remote-scanner-download</a> ',
      `Download the Remote Scanner application on iOS or Android.`,
      `Generate QR code`,
    ],
  },
  {
    step: 1,
    title: 'Grant Permissions to the Application',
    details: [
      '<b>Application for macOS</b>',
      '1. Launch the application and proceed to grant Accessibility.',
      '<img src="assets/images/ios_1.png" alt="" />',
      '2. Click “Open System Settings”',
      '<img src="assets/images/ios_2.png" alt="" />',
      '2. Enable for “Remote Scanner” application',
      '<img src="assets/images/ios_3.png" alt="" />',
      'Launch the application and proceed to grant Accessibility.',
      '1. Go to System Settings -> Privacy & Security',
      '<img src="assets/images/ios_4.png" alt="" />',
      '2. Accessibility',
      '<img src="assets/images/ios_5.png" alt="" />',
      '3. Enable for “Remote Scanner” application',
      '<img src="assets/images/ios_6.png" alt="" />',
      "<b>Launch the application with Administrator privileges.</b>",
      ""
    ],
  },
  {
    step: 2,
    title: 'Connect Two Devices',
    details: [
      '1. Launch the Remote Scanner Client application on the computer.',
      'On windows',
      '<img src="assets/images/ios_7.png" alt="" />',
      "On MacOS",
      '<img src="assets/images/ios_8.png" alt="" />',
      "2. On the mobile device, launch the Remote Scanner application.",
      '<img src="assets/images/ios_9.png" alt="" />',
      "3. Scan the QR code displayed on the Remote Scanner Client application on Windows or macOS.",
      '<img src="assets/images/ios_10.png" alt="" />',
      "4. Press the Connect button displayed on the mobile application and check connected status",
      '<img src="assets/images/ios_11.png" alt="" />'
    ],
  },
  {
    step: 3,
    title: 'Transfer Data from Mobile to Computer',
    details: [
      '1. Scan any QR/Barcode; the mobile device will display a Transfer button',
      '<img src="assets/images/ios_12.png" alt="" />',
      '2. Press Transfer, and the data will be sent to the connected computer.',
    ],
  },
];

export const UserGuide = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTab = (index) => {
    setActiveTab(index);
  };

  return (
    <div className='faq-area mt-5'>
      <Container>
        <div className='section-title text-left'>
          <h2 className='title'>Get Every Single Answer Here.</h2>
        </div>
        <Row>
          <Col className='col-lg-4'>
            {/* section title */}
            <div className='faq-accordion'>
              <Accordion>
                {dataUserGuide.map((item, index) => (
                  <Card key={index}>
                    <Card.Header>
                      <div
                        className={styles.wrapperCard}
                        onClick={() => handleTab(index)}
                      >
                        {item.title}
                      </div>
                    </Card.Header>
                  </Card>
                ))}
              </Accordion>
            </div>
            {/* faq accordion */}
          </Col>
          <Col className='col-lg-8'>
            {dataUserGuide[activeTab] && (
              <div
                className='fadeInUp'
                data-wow-duration='.5s'
                data-wow-delay='.2s'
              >
                <div className='single-faq'>
                  <h3 className='title'>
                    <span
                      className='counter'
                      style={{
                        fontSize: '35px',
                      }}
                    >
                      {dataUserGuide[activeTab].title}
                    </span>
                  </h3>
                  {dataUserGuide[activeTab].details && (
                    <ul>
                      {dataUserGuide[activeTab].details.map((detail, idx) => (
                        <li key={idx}>
                          <p dangerouslySetInnerHTML={{ __html: detail }}></p>
                        </li>
                      ))}
                    </ul>
                  )}
                  <i className='fal fa-archive' />
                </div>
              </div>
            )}
          </Col>
        </Row>
        {/* row */}
      </Container>
      {/* container */}
    </div>
  );
};
