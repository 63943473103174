import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import OurTechStack from '../../assets/images/our-techStack.png';
class LatestServicesPart extends React.Component {
  render() {
    return (
      <div className='team-member-area'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg='6'>
              <div className='section-title text-center'>
                <h2 className='title'>Our TechStack</h2>
              </div>
            </Col>
          </Row>
          {/* row */}
          <div className='container-fluid justify-content-center'>
            <img src={OurTechStack} alt='' />
          </div>
        </Container>
      </div>
    );
  }
}

export default LatestServicesPart;
