import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import servicesData from '../../assets/data/dataServices.json';
const ServicesItemPart = () => {
  let history = useHistory();

  // Hàm xử lý khi nhấn vào dịch vụ
  function handleClick(service) {
    // Lưu trữ dữ liệu dịch vụ vào localStorage
    localStorage.setItem('selectedService', service);

    // Chuyển hướng đến trang chi tiết dịch vụ
    history.push('/services-details');
  }

  return (
    <div className='services-item-area pt-90 pb-120'>
      <Container>
        <Row className='justify-content-center'>
          {servicesData.map((service) => (
            <Col lg='4' md='6' sm='8' key={service.id}>
              <div
                className='single-services-item mt-30'
                onClick={() => handleClick(service.keyServer)}
              >
                <img src={service.image} alt={service.title} />
                <div className='services-overlay'>
                  <i className={service.icon} />
                  <h4 className='title'>{service.title}</h4>
                  <p>{service.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServicesItemPart;
