import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FiMapPin } from 'react-icons/fi';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { PiPhoneCallLight } from 'react-icons/pi';
import { toast } from 'react-toastify';
import Layout from '../../layouts';
import styles from './contact.module.scss';
import ContactForm from './ContactForm';
const addressList = [
  {
    title: 'Head Office Address',
    description:
      ' Address: 126 Doc Ngu stress, Vinh Phuc, Ba Dinh, Ha Noi, Viet Nam',
    icon: FiMapPin, // Icon FiMapPin
  },
  {
    title: 'Email Address',
    description: ' information@megiservices.com',
    icon: HiOutlineMailOpen, // Icon FiHome
  },
  {
    title: 'Telephone',
    description: '(+84) 946689189',
    icon: PiPhoneCallLight, // Icon FiPhone
  },
  // Thêm nhiều phần tử khác nếu cần
];

class ContactPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  // componentDidMount() {
  // 	setTimeout(function () {
  // 		document.querySelector(".preloader").style = "display: none";
  // 	}, 5000);
  // }
  openModal = (event) => {
    this.setState({ isOpen: true });
    event.preventDefault();
  };

  formHandle = (event) => {
    toast.success('Thank you for contact with us!');
    event.preventDefault();
  };

  render() {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <div className={styles.contentInfo}>
            <div className={styles.elementorBackgroundOverlay}></div>
            <Row>
              <Col lg='6'>
                <div className={styles.elementorWidgetWrap}>
                  <h2 className={styles.elementorHeadingTitle}>
                    keep in touch with us
                  </h2>
                  <h3
                    className={`${styles.elementorHeadingTitle} ${styles.elementorHeadingTitleTow}`}
                  >
                    Get Information &amp; Lets Get In Touch With Our Team
                  </h3>
                  <p className={styles.elementorText}>
                    No matter what type of business you are running – big or
                    small – we can help you grow by building the perfect
                    solution for your needs. We work with startups, small and
                    medium businesses, and large enterprises to design perfect
                    products for each customer.
                  </p>
                  {addressList.map((address, index) => (
                    <AddressBox
                      key={index}
                      title={address.title}
                      description={address.description}
                      icon={address.icon} // Truyền icon vào component
                    />
                  ))}
                </div>
              </Col>
              <Col lg='6'>
                <div className={styles.wrapperLeft}>
                  <h3 className={styles.elementorHeadingTitle}>
                    Leave a Message
                  </h3>
                  <p>
                    Fill out the form below and we will get in touch with you
                    shortly.
                  </p>
                  <div className={styles.elementorWidgetWrap}>
                    <h3 className={styles.elementorHeadingTitle}>
                      Simple Contact Form
                    </h3>
                    <ContactForm />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className='map-area'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.8323509060087!2d105.80757848599983!3d21.03939303743945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab13e5eb584f%3A0x4e6b232b7d9cc9ef!2zMTI2IFAuIMSQ4buRYyBOZ-G7rywgVsSpbmggUGjDuiwgQmEgxJDDrG5oLCBIw6AgTuG7mWksIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1725703108822!5m2!1svi!2s'
              style={{ border: 0 }}
              allowFullScreen
              loading='lazy'
              width={'100%'}
              height={450}
              title='Our Localtion'
            />
          </div>
          {/*====== GET IN TOUCH PART ENDS ======*/}
        </div>
      </Layout>
    );
  }
}

export default ContactPage;
const AddressBox = ({ icon: IconComponent, title, description }) => {
  return (
    <div className={styles.elementorWidgetContainer}>
      <div className={styles.elementorIconBox}>
        <IconComponent />
      </div>
      <div className={styles.elementorIconBoxContent}>
        <h4 className={styles.elementorIconBoxTitle}>
          <span>{title}</span>
        </h4>
        <p className={styles.elementorIconBoxDescription}>{description}</p>
      </div>
    </div>
  );
};
