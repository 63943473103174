import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer
          className='footer-area footer-area-2 footer-area-1 bg_cover'
          style={{ backgroundImage: 'url(assets/images/footer-bg.jpg)' }}
        >
          <div className='footer-overlay'>
            <Container className='position-relative'>
              <Row>
                <Col lg='6' md='6'>
                  <div className='widget-item-2 widget-item-3 mt-30'>
                    <h4 className='title'>MegiServices</h4>
                    <ul>
                      <li>
                        Address: 126 Doc Ngu stress, Vinh Phuc, Ba Dinh, Ha Noi,
                        Viet Nam
                      </li>
                      <li>
                        Phone: <a href='tel:84946689189'>+84946689189</a>
                      </li>
                      <li>
                        Email:{' '}
                        <a href='mailto:information@megiservices.com' target='__blank'>
                          information@megiservices.com
                        </a>
                      </li>
                    </ul>
                  </div>{' '}
                  {/* widget item 3 */}
                </Col>

                <Col lg='' md='6'>
                  <div className='widget-item-2 widget-item-3 mt-30'>
                    <h4 className='title'>Working Hours</h4>
                    <ul>
                      <li>Monday - Friday: 9:00 - 22:00 (GMT +7:00)</li>
                      <li>Weekend and Holiday: 10:00 - 20:00 (GMT +7:00)</li>
                    </ul>
                  </div>
                </Col>
              </Row>{' '}
              {/* row */}
              <Row>
                <Col lg='12'>
                  <div className='footer-copyright'>
                    <p>
                      Copyright By@ <span>MegiService </span> -{' '}
                      {new Date().getFullYear()}
                    </p>
                  </div>{' '}
                  {/* footer copyright */}
                </Col>
              </Row>{' '}
              {/* row */}
            </Container>{' '}
            {/* container */}
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <div className='back-to-top back-to-top-2'>
          <a href='#backTotop'>
            <i className='fas fa-arrow-up' />
          </a>
        </div>
        {/*====== BACK TO TOP ======*/}
      </React.Fragment>
    );
  }
}

export default Footer;
