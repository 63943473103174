import React, { useState } from 'react';
import styles from './contact.module.scss';
const ContactForm = () => {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};
    if (!form.firstName) newErrors.firstName = 'This field is required.';
    if (!form.lastName) newErrors.lastName = 'This field is required.';
    if (!form.email) newErrors.email = 'This field is required.';
    if (!form.subject) newErrors.subject = 'This field is required.';
    if (!form.message) newErrors.message = 'This field is required.';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Handle form submission logic here
      console.log('Form submitted successfully!');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.wrapperForm}>
      <div className={styles.grid}>
        {/* First Name */}
        <div>
          <label className="block text-white mb-1" htmlFor="firstName">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="firstName"
            placeholder="First name here"
            className={`w-full p-2 border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded`}
            value={form.firstName}
            onChange={handleChange}
          />
          {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
        </div>

        {/* Last Name */}
        <div>
          <label className="block text-white mb-1" htmlFor="lastName">
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="lastName"
            placeholder="Last name here"
            className={`w-full p-2 border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded`}
            value={form.lastName}
            onChange={handleChange}
          />
          {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>}
        </div>

        {/* Email Address */}
        <div>
          <label className="block text-white mb-1" htmlFor="email">
            Email Address <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            placeholder="Add email"
            className={`w-full p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded`}
            value={form.email}
            onChange={handleChange}
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        {/* Subject */}
        <div>
          <label className="block text-white mb-1" htmlFor="subject">
            Subject <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="subject"
            placeholder="How can we help you?"
            className={`w-full p-2 border ${errors.subject ? 'border-red-500' : 'border-gray-300'} rounded`}
            value={form.subject}
            onChange={handleChange}
          />
          {errors.subject && <p className="text-red-500 text-sm mt-1">{errors.subject}</p>}
        </div>
      </div>

      {/* Message */}
      <div className="mt-4">
        <label className="block text-white mb-1" htmlFor="message">
          Message <span className="text-red-500">*</span>
        </label>
        <textarea
          name="message"
          placeholder="Comments"
          className={`w-full p-2 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded`}
          rows="5"
          value={form.message}
          onChange={handleChange}
        />
        {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className={styles.submitButton}
      >
        SEND MESSAGE
      </button>
    </form>
  );
};

export default ContactForm;
