import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import servicesData from '../../assets/data/dataServices.json';

const ServicesOptimizationPart = () => {
  const [serviceInfo, setServiceInfo] = useState({
    title: '',
    description: '',
    icon: '',
    image: '',
  });

  useEffect(() => {
    // Get data from localStorage
    const selectedService = localStorage.getItem('selectedService');

    if (selectedService) {
      console.log('useEffect  selectedService:', selectedService);
      console.log('useEffect  selectedService:', servicesData);
      // Find the matching service
      const service = servicesData.find((s) => s.keyServer === selectedService);
      console.log("useEffect  service:", service)
      if (service) {
        setServiceInfo({
          title: service.title,
          description: service.description,
          icon: service.icon,
          image: service.image,
        });
      } else {
        setServiceInfo({
          title: 'No information available',
          description: '',
          icon: '',
          image: '',
        });
      }
    }
  }, []);
  const handleCall = () => {
    window.location.href = "tel:+84946689189";
  };
  return (
    <div className='services-optimization-area pt-80 pb-120'>
      <Container>
        <Row className='align-items-center'>
          <Col lg='4' md='6'>
            <div className='services-optimization-thumb mt-40'>
              <img src='assets/images/services-op-1.jpg' alt='' />
            </div>
            <div className='services-optimization-thumb-2'>
              <img src='assets/images/services-op-2.jpg' alt='' />
            </div>
          </Col>
          <Col lg='6' className='order-lg-2 order-1'>
            <div className='services-optimization-content mt-40'>
              <span>Search Enging Optimization</span>
              <h3 className='title'>{serviceInfo.title}</h3>
              <p>
              {serviceInfo.description}
              </p>
              <div className='action-support-item' onClick={handleCall}>
                <p>Make A Call</p>
                <span>(+84) 946689189</span>
                <i className='fal fa-phone' />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ServicesOptimizationPart;
