import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { UserGuide } from './conponet/userGuide';
export const RemoteScannerUserGuide = () => {
  return (
    <Layout>
      <div className='page-title-area'>
        <Container>
          <Row>
            <Col lg='12'>
              <div className='page-title-item text-center'>
                <h2 className='title'>User Guide</h2>
                <nav aria-label='breadcrumb'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to={'/'}>Home </Link>
                    </li>
                    <li className='breadcrumb-item active' aria-current='page'>
                      User Guide
                    </li>
                  </ol>
                </nav>
              </div>
              {/* page title */}
            </Col>
          </Row>
          {/* row */}
        </Container>
      </div>
      <UserGuide />
    </Layout>
  );
};
